import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  openState = false;
  icon = 'menu';
  authenticated: boolean;

  toggle() {
    this.openState = !this.openState;
    this.icon = this.openState ? 'menu' : 'close';
  }

  @HostListener('document:click', ['$event'])
  click(event: PointerEvent) {
    if (!this.openState) {
      return;
    }
    const target: any = event.target;
    if (!target.classList.contains('menu-button')) {
      this.openState = false;
    }
  }
}
