<aside
  [class.open]="openState"
  class="fixed top-0 left-0 z-40 overflow-hidden transition-all duration-500 ease-in-out bg-white"
>
  <div class="flex w-screen lg:w-auto">
    <button (click)="toggle()" class="z-50 menu-button">
      <svg
        aria-label="menu"
        width="18px"
        height="14px"
        class="pointer-events-none lg:hidden"
      >
        <use [attr.xlink:href]="openState ? '#close-icon' : '#menu-icon'" />
      </svg>
    </button>

    <a class="flex px-8 mx-auto menu-logo" routerLink="/">
      <img
        src="assets/images/logo.svg"
        alt="Care4migraine"
        height="80"
        width="196"
      />
    </a>
  </div>

  <nav
    class="fixed left-0 z-40 pb-3 overflow-x-hidden overflow-y-auto transition-transform duration-500 ease-in-out bg-white lg:transition-none navbar"
  >
    <ul class="mt-0">
      <li>
        <a routerLink="/over-migraine" routerLinkActive="active">
          <svg width="15px" height="21px">
            <use xlink:href="#about-icon" />
          </svg>
          <div class="item-content">
            <span>Over migraine</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Lees alles over migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/leven-met-migraine" routerLinkActive="active">
          <svg aria-label="Over migraine" width="18px" height="14px">
            <use xlink:href="#migraine-icon" />
          </svg>
          <div class="item-content">
            <span>Leven met migraine</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Over het dagelijkse leven en omgaan met migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/ervaringsverhalen" routerLinkActive="active">
          <svg width="20px" height="18px">
            <use xlink:href="#blog-icon" />
          </svg>
          <div class="item-content">
            <span>Ervaringsverhalen</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Lees verhalen over het leven van mensen met migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/expert-aan-het-woord" routerLinkActive="active">
          <svg width="20px">
            <use xlink:href="#expert-icon" />
          </svg>
          <div class="item-content">
            <span>Expert aan het woord</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              <!-- TODO: Update text to new expert text (hasn't been provided, yet) -->
              Lees verhalen over het leven van mensen met migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a
          routerLink="/bijeenkomsten/categorie/gepland"
          routerLinkActive="active"
        >
          <svg width="22px" height="22px">
            <use xlink:href="#event-icon" />
          </svg>
          <div class="item-content">
            <span>Bijeenkomsten</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Meld je aan voor een cursus of bijeenkomst
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/indeaanval" routerLinkActive="active">
          <svg width="20px" height="16px">
            <use xlink:href="#indeaanval-icon" />
          </svg>
          <div class="item-content">
            <span>#indeaanval</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              De videoserie over waarheden, feiten en fabels
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/podcasts" routerLinkActive="active">
          <svg width="16px" height="20px">
            <use xlink:href="#podcast-icon" />
          </svg>
          <div class="item-content">
            <span>Podcasts</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Beluister interessante podcasts over migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/ontspanningsoefeningen" routerLinkActive="active">
          <svg width="16px" height="20px">
            <use xlink:href="#relaxation-icon" />
          </svg>
          <div class="item-content">
            <span>Ontspanningsoefeningen</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Doe mee met de oefeningen en ontspan!
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/gesprekshulp" routerLinkActive="active">
          <svg width="16px" height="20px">
            <use xlink:href="#conversation-aid-icon" />
          </svg>
          <div class="item-content">
            <span>Gesprekshulp</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Hulp bij het voorbereiden van een gesprek met jouw neuroloog
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/migraineapp" routerLinkActive="active">
          <svg width="13px" height="20px">
            <use xlink:href="#app-icon" />
          </svg>

          <div class="item-content">
            <span>migraineAPP</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Meer grip op je migraine met je eigen hoofdpijndagboek
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/veelgestelde-vragen" routerLinkActive="active">
          <svg width="20px" height="20px">
            <use xlink:href="#faq-icon" />
          </svg>

          <div class="item-content">
            <span>Veelgestelde vragen</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              De meest gestelde vragen over (leven met) migraine
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/nieuwsbrief" routerLinkActive="active">
          <svg width="20px" height="20px">
            <use xlink:href="#newsletter-icon" />
          </svg>
          <div class="item-content">
            <span>Nieuwsbrief</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Ontvang elke 2 maanden handige migraine-updates
            </small>
          </div>
        </a>
      </li>
      <li>
        <a routerLink="/contact" routerLinkActive="active">
          <svg width="20px" height="20px">
            <use xlink:href="#contact-icon" />
          </svg>
          <div class="item-content">
            <span>Contact</span>
            <small class="visible block text-xs lg:hidden lg:invisible">
              Vragen? Neem contact met ons op
            </small>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</aside>
