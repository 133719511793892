import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContentPageDetailComponent } from './pages/content-page/detail/content-page-detail.component';
import { NotFoundComponent } from './pages/not-found/not-found/not-found.component';
import { SlugResolver } from './resolver/slug.resolver';
import { BaseRedirectResolver } from './resolver/base-redirect.resolver';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'ervaringsverhalen',
        loadChildren: () =>
          import('./modules/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./modules/blog/blog.module').then((m) => m.BlogModule),
        data: { redirect: 'ervaringsverhalen' },
        resolve: { base: BaseRedirectResolver },
      },
      {
        path: 'indeaanval-vraag',
        data: { crumbs: ['#indeaanval', 'Stel een vraag'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/in-de-aanval/landing/in-de-aanval-landing.component'
              ).then((c) => c.InDeAanvalLandingComponent),
            data: {
              crumbs: [],
              title:
                '#indeaanval: wat wil jij weten over migraine? | Care4migraine',
              metadata:
                'Heb jij een brandende vraag over migraine? Of twijfel je aan wat feit is of fabel? Stel je vraag en krijg antwoord tijdens de uitzending van #indeaanval.',
            },
          },
        ],
      },
      {
        path: 'indeaanval',
        loadChildren: () =>
          import('./modules/indeaanval/indeaanval.module').then(
            (m) => m.InDeAanvalModule
          ),
      },
      {
        path: 'bijeenkomsten',
        loadChildren: () =>
          import('./modules/event/event.module').then((m) => m.EventModule),
      },
      {
        path: 'veelgestelde-vragen',
        loadChildren: () =>
          import('./modules/faq/faq.module').then((m) => m.FAQModule),
      },
      {
        path: 'leven-met-migraine',
        loadChildren: () =>
          import('./modules/migraine/migraine.module').then(
            (m) => m.MigraineModule
          ),
      },
      {
        path: 'expert-aan-het-woord',
        loadChildren: () =>
          import('./modules/expert/expert.module').then((m) => m.ExpertModule),
      },
      {
        path: 'over-migraine',
        loadChildren: () =>
          import('./modules/about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'nieuwsbrief',
        loadChildren: () =>
          import('./modules/newsletter/newsletter.module').then(
            (m) => m.NewsletterModule
          ),
      },
      {
        path: 'podcasts',
        loadChildren: () =>
          import('./modules/podcast/podcast.module').then(
            (m) => m.PodcastModule
          ),
      },
      {
        path: 'ontspanningsoefeningen',
        loadChildren: () =>
          import('./modules/music/music.module').then((m) => m.MusicModule),
      },
      {
        path: 'downloads',
        loadChildren: () =>
          import('./modules/download/download.module').then(
            (m) => m.DownloadModule
          ),
      },
      {
        path: 'gesprekshulp',
        loadChildren: () =>
          import('./modules/conversation-aid/conversation-aid.module').then(
            (m) => m.ConversationAidModule
          ),
      },
      {
        path: 'migraineapp',
        loadChildren: () =>
          import('./modules/migraineapp/migraineapp.module').then(
            (m) => m.MigraineAppModule
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./modules/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
      {
        path: 'blijf-niet-zitten-met-hoofdpijn',
        loadComponent: () => import('./pages/possibilities/exploration/exploration.component')
          .then((m) => m.ExplorationComponent),
        data: {
          crumbs: [],
          title: 'Blijf niet zitten met hoofdpijn | Care4migraine',
          metadata: 'Blijf niet zitten met hoofdpijn',
        },
      },
      {
        path: 'blijf-niet-zitten-met-migraine',
        loadComponent: () => import('./pages/possibilities/migraine/migraine.component')
          .then((m) => m.MigraineComponent),
        data: {
          crumbs: [],
          title: 'Blijf niet zitten met migraine | Care4migraine',
          metadata: 'Blijf niet zitten met migraine',
        },
      },
      {
        path: ':prefix/situatiecheck',
        loadComponent: () => import('./pages/possibilities/situation-check/situation-check.component')
          .then((m) => m.SituationCheckComponent),
        data: {
          crumbs: [],
          title: 'Blijf niet zitten met migraine | Care4migraine',
          metadata: 'Blijf niet zitten met migraine',
        },
      },
      {
        path: 'hoofdpijn',
        redirectTo: 'blijf-niet-zitten-met-hoofdpijn',
        pathMatch: 'full'
      },
      {
        path: 'migraine',
        redirectTo: 'blijf-niet-zitten-met-migraine',
        pathMatch: 'full'
      },
      {
        path: '404',
        data: { crumbs: ['404'], title: '404' },
        loadComponent: () =>
          import('./pages/not-found/not-found/not-found.component').then(
            (c) => c.NotFoundComponent
          ),
      },
      {
        path: ':id',
        data: { crumbs: [':id'] },
        loadComponent: () =>
          import(
            './pages/content-page/detail/content-page-detail.component'
          ).then((c) => c.ContentPageDetailComponent),
        resolve: {
          slug: SlugResolver,
        },
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/404'
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
