import { Component } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  date: string;
  title = 'care4migraine web';

  constructor(private titleService: TitleService, authService: AuthService) {
    // AJOVY is no longer supported on the website, so we can safely log everyone out.
    if (authService.hasEuNumberCheck()) {
      authService.setEuNumber(null);
    }

    if (authService.isAuthenticated && !authService.hasRole('ROLE_ADMIN')) {
      authService.logout('/');
    }

    const today = new Date();
    this.date =
      today.getDate() + '' + (today.getMonth() + 1) + today.getFullYear();
  }
}
